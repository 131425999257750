(() => {
    const slides = [...document.querySelectorAll('.slider .slide')];

    const getNextSlide = (i) => {
        let nextIndex = i + 1;

        if (nextIndex === slides.length) {
            nextIndex = 0;
        }

        return slides[nextIndex];
    }

    if (slides.length > 1) {
        setInterval(() => {
            for (let i = 0; i < slides.length; i++) {
                const slide = slides[i];
                const nextSlide = getNextSlide(i);

                if (!slide.classList.contains('hidden')) {
                    nextSlide.classList.remove('hidden');

                    setTimeout(() => {
                        slide.classList.add('hidden');
                    }, 200);

                    break;
                }
            }
        }, 5000);
    }
})();
